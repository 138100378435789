import React from 'react'
import Layout from '../components/Layout'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

export default () => (
  <Layout>
    <Grid container justify="center" alignContent="center" alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography
          variant="h1"
          style={{ paddingTop: '32px', textAlign: 'center' }}
        >
          Thank you!
        </Typography>
        <Typography
          variant="body1"
          style={{ paddingTop: '32px', textAlign: 'center' }}
        >
          Your feedback is greatly appreciated <span role='img'  aria-labelledby='smiling'>😃</span>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} />
    </Grid>
  </Layout>
)
